import React, { forwardRef, MutableRefObject } from 'react';
import { WithTranslationsProps } from 'react-utilities';
import classNames from 'classnames';
import { TBuildEventProperties } from './GameTileUtils';
import { TGameData, TGetFriendsResponse } from '../types/bedev1Types';
import { TComponentType, TPlayButtonStyle, TPlayerCountStyle } from '../types/bedev2Types';
import GameTileTypeMap from './GameTileTypeMap';
import '../../../../css/common/_gameCarousel.scss';

export type TGameCarouselProps = {
  gameData: TGameData[];
  friendData?: TGetFriendsResponse[];
  translate: WithTranslationsProps['translate'];
  buildEventProperties: TBuildEventProperties;
  tileRef?: MutableRefObject<HTMLDivElement | null>;
  componentType?: TComponentType;
  playerCountStyle?: TPlayerCountStyle;
  playButtonStyle?: TPlayButtonStyle;
};

export const GameCarousel = forwardRef<HTMLDivElement, TGameCarouselProps>(
  (
    {
      gameData,
      buildEventProperties,
      translate,
      friendData,
      componentType,
      playerCountStyle,
      playButtonStyle,
      tileRef
    }: TGameCarouselProps,
    forwardedRef
  ) => {
    const carouselClassName = classNames('game-carousel', {
      'grid-tile-carousel': componentType === TComponentType.GridTile
    });

    return (
      <div data-testid='game-carousel' ref={forwardedRef} className={carouselClassName}>
        {gameData.map((data, positionId) => (
          <GameTileTypeMap
            componentType={componentType}
            playerCountStyle={playerCountStyle}
            playButtonStyle={playButtonStyle}
            ref={tileRef}
            // eslint-disable-next-line react/no-array-index-key
            key={positionId}
            id={positionId}
            gameData={data}
            translate={translate}
            buildEventProperties={buildEventProperties}
            friendData={friendData}
          />
        ))}
      </div>
    );
  }
);
GameCarousel.displayName = 'GameCarousel';
export default GameCarousel;
