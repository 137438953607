import React from 'react';
import { TranslateFunction } from 'react-utilities';
import { abbreviateNumber } from 'core-utilities';
import { TGetGameDetails, TGetUniverseVoiceStatus } from '../../common/types/bedev1Types';
import { getNumberFormat, dateTimeFormatter } from '../../common/utils/parsingUtils';
import { gameDetailsPage } from '../../common/constants/configConstants';
import { FeatureCatalog, FeatureGameDetails } from '../../common/constants/translationConstants';
import GearOptionsDisplay from './GearOptionsDisplay';
import GameDescriptionTableChatFeaturesDisplay from './GameDescriptionTableChatFeaturesDisplay';

type TDescriptionLabel = {
  label: string;
  value: React.ReactNode;
};

type TGameDescriptionTableProps = {
  gameDetails: TGetGameDetails;
  universeVoiceStatus: TGetUniverseVoiceStatus;
  translate: TranslateFunction;
};

const GameDescriptionTableValueText = ({
  valueText
}: {
  valueText: string | JSX.Element;
}): JSX.Element => {
  return <p className='text-lead font-caption-body'>{valueText}</p>;
};

const genreToTranslationKey: Record<string, string> = {
  All: FeatureCatalog.LabelGenreAll,
  Adventure: FeatureCatalog.LabelGenreAdventure,
  Building: FeatureCatalog.LabelGenreBuilding,
  Comedy: FeatureCatalog.LabelGenreComedy,
  Fighting: FeatureCatalog.LabelGenreFighting,
  FPS: FeatureCatalog.LabelGenreFPS,
  Horror: FeatureCatalog.LabelGenreHorror,
  Medieval: FeatureCatalog.LabelGenreMedieval,
  Military: FeatureCatalog.LabelGenreMilitary,
  Naval: FeatureCatalog.LabelGenreNaval,
  RPG: FeatureCatalog.LabelGenreRPG,
  'Sci-Fi': FeatureCatalog.LabelGenreSciFi,
  Sports: FeatureCatalog.LabelGenreSports,
  'Town and City': FeatureCatalog.LabelGenreTownAndCity,
  Western: FeatureCatalog.LabelGenreWestern
};

const GameDescriptionTable = ({
  gameDetails,
  universeVoiceStatus,
  translate
}: TGameDescriptionTableProps): JSX.Element => {
  const getTranslatedGenreText = (genre: string) => {
    if (genre in genreToTranslationKey && translate(genreToTranslationKey[genre])) {
      return translate(genreToTranslationKey[genre]);
    }

    return genre;
  };

  const descriptionTable: TDescriptionLabel[] = [
    {
      label: translate(FeatureGameDetails.LabelPlaying),
      value: <GameDescriptionTableValueText valueText={getNumberFormat(gameDetails.playing)} />
    },
    {
      label: translate(FeatureGameDetails.LabelFavorites),
      value: (
        <GameDescriptionTableValueText valueText={getNumberFormat(gameDetails.favoritedCount)} />
      )
    },
    {
      label: translate(FeatureGameDetails.LabelVisits),
      value: (
        <GameDescriptionTableValueText
          valueText={abbreviateNumber.getTruncValue(
            gameDetails.visits,
            undefined,
            undefined,
            gameDetailsPage.visitsTruncationDigitsAfterDecimalPoint
          )}
        />
      )
    },
    {
      label: translate(FeatureGameDetails.LabelCreated),
      value: (
        <GameDescriptionTableValueText
          valueText={dateTimeFormatter.getShortDate(gameDetails.created)}
        />
      )
    },
    {
      label: translate(FeatureGameDetails.LabelUpdated),
      value: (
        <GameDescriptionTableValueText
          valueText={dateTimeFormatter.getShortDate(gameDetails.updated)}
        />
      )
    },
    {
      label: translate(FeatureGameDetails.LabelMaxPlayers),
      value: <GameDescriptionTableValueText valueText={getNumberFormat(gameDetails.maxPlayers)} />
    },
    {
      label: translate(FeatureGameDetails.LabelGenre),
      value: <GameDescriptionTableValueText valueText={getTranslatedGenreText(gameDetails.genre)} />
    },
    {
      label: translate(FeatureGameDetails.LabelAllowedGear),
      value: (
        <GameDescriptionTableValueText
          valueText={
            <GearOptionsDisplay
              genre={gameDetails.genre}
              isGenreEnforced={gameDetails.isGenreEnforced}
              isAllGenre={gameDetails.isAllGenre}
              allowedGearCategories={gameDetails.allowedGearCategories}
            />
          }
        />
      )
    }
  ];

  if (
    universeVoiceStatus.isUniverseEnabledForVoice ||
    universeVoiceStatus.isUniverseEnabledForAvatarVideo
  ) {
    const communicationFeatures = {
      label: translate(FeatureGameDetails.LabelCommunication),
      value: (
        <GameDescriptionTableChatFeaturesDisplay
          universeVoiceStatus={universeVoiceStatus}
          translate={translate}
        />
      )
    };

    descriptionTable.splice(gameDetailsPage.voiceLabelIndex, 0, communicationFeatures);
  }

  return (
    <ul className='border-top border-bottom game-stat-container'>
      {descriptionTable.map(stat => (
        <li key={stat.label} className='game-stat'>
          <p className='text-label text-overflow font-caption-header'>{stat.label}</p>
          {stat.value}
        </li>
      ))}
    </ul>
  );
};

export default GameDescriptionTable;
